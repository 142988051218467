export const precisionAgriculture = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    // disable: true,
    type: 'report',
    time: '11:40',
    desc: 'Фермерство 4.0 з xFarm: Як технології змінюють агробізнес',
    speakerName: 'Олександра Воловик',
    speakerDesc: 'Business Development Manager',
    speakerImg: new URL('../assets/speakers/volovik.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/volovik_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:20',
    desc: '5 років, 500 експериментів на 50 тис. га: Рекомендації щодо внесення азоту на кукурудзі для диференційованого та стандартного підходів',
    speakerName: 'Всеволод Генін',
    speakerDesc: 'Head of agricultural research, співзасновник',
    speakerImg: new URL('../assets/speakers/vsevolod.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vsevolod_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '11:20',
    desc: 'Чому при фразі «Точне землеробство» у агронома сіпається око?',
    speakerName: 'Андрій Капріца',
    speakerDesc: 'ФГ «Флора А.А.»',
    speakerImg: new URL('../assets/speakers/caprica.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/caprica_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '16:20',
    desc: 'Дані без діла: чому 90% інформації з агросистем не працює і як подолати опір технологіям',
    speakerName: 'Дмитро Грушецький',
    speakerDesc: 'співвласник ПП "Дніпро"',
    speakerImg: new URL('../assets/speakers/grushetsky.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/grushetsky_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '11:00',
    desc: 'Секрети точного землеробства про які вам більше ніхто не розповість',
    speakerName: 'Валерія Ващенко',
    speakerDesc: 'ГК «Землероб»',
    speakerImg: new URL('../assets/speakers/vaschenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:20',
    desc: 'Що після Точного Землеробства?',
    speakerName: 'Євген Сапіженко',
    speakerDesc: 'Заступник директора агробізнесу з інноваційного та цифрового розвитку «Кернел»',
    speakerImg: new URL('../assets/speakers/sapishenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/sapishenko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:00',
    desc: 'Система точного землеробства та інструмент стартового живлення рослин',
    speakerName: 'Григорій Кобзар',
    speakerDesc: 'Продакт-менеджер Vitagro Partner з МКД, РКД та інокулянтів',
    speakerImg: new URL('../assets/speakers/kobzar.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kobzar_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '17:00',
    desc: 'Управління найціннішим ресурсом точного землеробства',
    speakerName: 'Роман Горобець',
    speakerDesc: 'СФГ "АСТРА"',
    speakerImg: new URL('../assets/speakers/gorobec.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/gorobec_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:40',
    desc: 'Моніторинг та аналіз показників якості роботи техніки',
    speakerName: 'Дмитро Зайцев',
    speakerDesc: 'Керівник управління розвитку та інновацій ',
    speakerImg: new URL('../assets/speakers/zaycev.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/zaycev_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '15:40',
    desc: 'Як технологія RTK підвищує ефективність аграріїв',
    speakerName: 'Аліна Туряниця',
    speakerDesc: 'керівник напряму впровадження точного позиціонування для агробізнесу',
    speakerImg: new URL('../assets/speakers/turynica.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/turynica_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '16:40',
    desc: 'На перетині інновацій точного землеробства та штучного інтелекту',
    speakerName: 'Віталій Шуберанський',
    speakerDesc: 'фермер, власник ТОВ "Френдт"',
    speakerImg: new URL('../assets/speakers/shuberansky.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/shuberansky_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '16:00',
    desc: 'Перехід на диференційний посів та внесення добирв: з чого почати,  скільки це коштує та коли окупиться?',
    speakerName: 'Богдан Круглик',
    speakerDesc: 'Директор «Френдт»',
    speakerImg: new URL('../assets/speakers/kruglic.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/shuberansky_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:40',
    desc: 'Програма DIFM - планування азотного живлення за використання елементів точного землеробства',
    speakerName: 'Девід С.Баллок',
    speakerDesc: 'Професор економіки с/г Університету Іллінойсу та Наццентру суперкомп`ютерних додатків (NCSA)',
    speakerImg: new URL('../assets/speakers/balluk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kemeron_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '08:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'break',
    time: '09:40',
    title: 'ОФІЦІЙНЕ ВІДКРИТТЯ REFARM 2025',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '14:00',
    title: 'ВЕЛИКИЙ РОЗІГРАШ ПРИЗІВ',
  },
  {
    type: 'break',
    time: '17:40',
    title: 'ВЕЧІРКА ДЛЯ УСІХ УЧАСНИКІВ КОНФЕРЕНЦІЇ🌽',
  },
];
