export const technology = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tech_sas_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tech_sas_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.facebook.com/zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '10:20',
    desc: 'Інноваційні мікробні біотехнології - основний ресурс формування рентабельного та прибуткового рослинництва',
    speakerName: 'Людмила Білявська',
    speakerDesc: 'Доктор біологічних наук, директор з науки ТОВ “ Лайф Біохем”',
    speakerImg: new URL('../assets/speakers/bilyavska.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/andrushenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:20',
    desc: 'Насіння соняшнику і кукурудзи: прогнози, тренди та технологічні рішення 2025',
    speakerName: 'Ольга Молокович',
    speakerDesc: 'PhD, керівник відділу маркетингу MAS Seeds',
    speakerImg: new URL('../assets/speakers/molokovich.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/molokovich_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '15:20',
    desc: 'Економіка обприскування: як сучасні підходи допомагають заощаджувати.',
    speakers: [
      {
        speakerName: 'Мирослав Гуранський',
        speakerDesc: 'Заступник генерального директора з технічних питань та інновацій компанії ІМК',
        speakerImg: new URL('../assets/speakers/guransky.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/krivickiy_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Денис Муштин',
        speakerDesc: 'Представник компанії TECNOMA в Україні',
        speakerImg: new URL('../assets/speakers/mushtin.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/mushtin_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'reportMulti',
    time: '10:40',
    desc: 'Не більше, а розумніше: підвищуємо ефективність добрив та ЗЗР.',
    speakers: [
      {
        speakerName: 'Олександр Карнаух',
        speakerDesc: 'продакт менеджер відділу добрив і мікродобрив ГК «Агро Експерт»',
        speakerImg: new URL('../assets/speakers/alex_karnaux.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/alex_karnaux_agroexpert.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Людвікас Бабаляускас',
        speakerDesc: 'Global sales director, NANDO',
        speakerImg: new URL('../assets/speakers/ludbabalyauskas.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/ludbabalyauskas_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'reportMulti',
    time: '15:40',
    desc: 'Особливості вирощування куркурудзи та сої в умовах обмежених опадів',
    speakers: [
      {
        speakerName: 'Кларк Колмен',
        speakerDesc: 'Фермер з США',
        speakerImg: new URL('../assets/speakers/coleman.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/coleman_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Кемерон Пірс',
        speakerDesc: 'Власник компанії Bayer Handelsvertretung',
        speakerImg: new URL('../assets/speakers/kemeron.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/kemeron_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Роман Гринишин',
        speakerDesc: 'Власник Travelite AGRO',
        speakerImg: new URL('../assets/speakers/grinshin.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/grinshin_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'report',
    time: '11:00',
    desc: 'Гуси врятували Рим, а BTU рятує від стресів. Механізми роботи біопрепаратів як антистресантів.',
    speakerName: 'Бухонська Ярослава',
    speakerDesc: 'Фізіолог рослин BTU',
    speakerImg: new URL('../assets/speakers/buxonska.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/buxonska_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:40',
    desc: '1000 +1 питання агроному. Дізнайся як заробити свій мільйон в 2025',
    speakerName: 'Богдан Лукіянчук',
    speakerDesc: 'Agro Digital Holding',
    speakerImg: new URL('../assets/speakers/b_lukiyanchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/b_lukiyanchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:20',
    desc: 'Дефіцит вологи: виклики та рішення для аграріїв',
    speakerName: 'Сергій Суровий',
    speakerDesc: 'Фермер',
    speakerImg: new URL('../assets/speakers/suroviy.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/suroviy_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:40',
    desc: 'Sustainable transition and importance of cover crops',
    speakerName: 'Adam Mikołajczak',
    speakerDesc: 'General Manager Central Eastern Europe, eAgronom',
    speakerImg: new URL('../assets/speakers/adam.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/adam_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '12:00',
    desc: 'Публічне інтерв`ю Владислава Кривозуба',
    speakers: [
      {
        speakerName: 'Владислав Кривозуб',
        speakerDesc: 'фермер, СФГ Кривозуб С.І.',
        speakerImg: new URL('../assets/speakers/krivozub.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/krivozub_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Дмитро Грушецький',
        speakerDesc: 'співвласник ПП "Дніпро"',
        speakerImg: new URL('../assets/speakers/grushetsky.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/grushetsky_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Віталій Шуберанський',
        speakerDesc: 'фермер, власник ТОВ "Френдт"',
        speakerImg: new URL('../assets/speakers/shuberansky.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/shuberansky_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'report',
    time: '10:00',
    desc: 'Революція у висівних технологіях: майбутнє швидкісного посіву вже сьогодні',
    speakerName: 'Андрій Мул',
    speakerDesc: 'Менеджер з питань регіонального розвитку',
    speakerImg: new URL('../assets/speakers/mul.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/mul_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '08:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'break',
    time: '09:40',
    title: 'ОФІЦІЙНЕ ВІДКРИТТЯ REFARM 2025',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '14:00',
    title: 'ВЕЛИКИЙ РОЗІГРАШ ПРИЗІВ',
  },
  {
    type: 'break',
    time: '17:30',
    title: 'АГРО СТЕНДАП😂',
  },
  {
    type: 'break',
    time: '17:40',
    title: 'ВЕЧІРКА ДЛЯ УСІХ УЧАСНИКІВ КОНФЕРЕНЦІЇ🌽',
  },
  {
    type: 'break',
    time: '17:00',
    title: 'ПЕРЕРВА ПЕРЕД ВЕЧІРКОЮ',
  },
  {
    type: 'break',
    time: '16:50',
    title: 'СЕКРЕТНИЙ СПІКЕР',
  },
];
