export const precisionAgriculture2 = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'break',
    time: '11:00 - 15 : 40',
    title: 'ЗАПИС ПОДКАСТУ від ютуб каналу АГРОСФЕРА',
  },
  {
    // disable: false,
    type: 'reportMulti',
    time: '11:00 - 15 : 40',
    desc: 'ЗАПИС ПОДКАСТУ від ютуб каналу АГРОСФЕРА',
    speakers: [
      {
        speakerName: 'Дмитро Грушецький',
        speakerDesc: 'співвласник ПП "Дніпро"',
        speakerImg: new URL('../assets/speakers/grushetsky.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/monashok_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Віталій Шуберанський',
        speakerDesc: 'фермер, власник ТОВ "Френдт"',
        speakerImg: new URL('../assets/speakers/shuberansky.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/monashok_logo.png?as=webp', import.meta.url),
      },
    ],
  },
];
