export const production = [
  {
    type: 'ad',
    img: new URL('../assets/ads/v_goose.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/v_goose.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.refarm.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '12:20',
    desc: 'Як зі звичайного продукту для зважування автомобілів зробити революцію в сфері?',
    speakerName: 'Іван Мовчан',
    speakerDesc: 'СЕО Українська Вагова Компанія',
    speakerImg: new URL('../assets/speakers/movchan.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/movchan_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:40',
    desc: 'Відходи в доходи або як комахи змінять світ',
    speakerName: 'Михайло Кобченко',
    speakerDesc: 'співвласник групи компаній',
    speakerImg: new URL('../assets/speakers/kobchenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kobchenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:40',
    desc: 'Технічні коноплі в Україні: шлях до відродження',
    speakerName: 'Ігор Дідок',
    speakerDesc: 'Комерційний директор',
    speakerImg: new URL('../assets/speakers/didok.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/didok_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:20',
    desc: 'Ризики і можливості на прикладі виробництва бренду Healthy&Wealthy.',
    speakerName: 'Діана Мірошніченко',
    speakerDesc: 'директорка Healthy&Wealthy',
    speakerImg: new URL('../assets/speakers/mirosh.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/mirosh_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:40',
    desc: 'Чому кооперація - це агротренд №1 для виробників',
    speakerName: 'Наталія Табінська',
    speakerDesc: 'Голова кооперативу "Фермерська родина", засновниця с/г центру «Фермерська країна»',
    speakerImg: new URL('../assets/speakers/tabinskya.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/tabinskya_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:20',
    desc: 'Власний елеватор: кейс СФГ "Урожай"',
    speakerName: 'Володимир Шевченко',
    speakerDesc: 'фермер СФГ "Урожай"',
    speakerImg: new URL('../assets/speakers/shevchenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/shevchenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:40',
    desc: 'ФЕРМЕР ШУКАЄ АГРОНОМА',
    speakerName: 'ШУКАЮ АГРОНОМА',
    speakerDesc: 'Докучаєвські Чорноземи',
    speakerImg: new URL('../assets/speakers/searchagronom.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '08:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'break',
    time: '09:40',
    title: 'ОФІЦІЙНЕ ВІДКРИТТЯ REFARM 2025',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '14:00',
    title: 'ВЕЛИКИЙ РОЗІГРАШ ПРИЗІВ',
  },
  {
    type: 'break',
    time: '17:40',
    title: 'ВЕЧІРКА ДЛЯ УСІХ УЧАСНИКІВ КОНФЕРЕНЦІЇ🌽',
  },
];
