export const business = [
  {
    type: 'ad',
    img: new URL('../assets/ads/agricole_b.gif', import.meta.url),
    height: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '11:20',
    desc: 'Сучасний ринок землі: від стратегії до результату.',
    speakerName: 'Роман Барало',
    speakerDesc: 'Генеральний директор компанії Доброзем',
    speakerImg: new URL('../assets/speakers/baralo.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/baralo_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:00',
    desc: 'Як фермеру захисти свою продукцію. Що робити, якщо зерновий склад знищено',
    speakerName: 'Анна Кухарчук',
    speakerDesc: 'Керуючий партнер компанії, адвокат',
    speakerImg: new URL('../assets/speakers/annakux.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/annakux_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:00',
    desc: 'Агроконсалтинг як драйвер урожайності: від точкових рішень до системного підходу',
    speakerName: 'Роман Рибак',
    speakerDesc: 'СЕО агроконсалтингової компанії ProPole',
    speakerImg: new URL('../assets/speakers/rybak.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/rybak_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:20',
    desc: 'Порядок визнання підприємства критично важливим: бронювання працівників.',
    speakerName: 'Андрій Кравчук',
    speakerDesc: 'бізнес-адвокат, операційний директор юридичної компанії',
    speakerImg: new URL('../assets/speakers/kravchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kravchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:00',
    desc: 'Оптимальні фінансові рішення в умовах невизначеності',
    speakerName: 'Катерина Гладишева',
    speakerDesc: 'Директорка з малого і середнього бізнесу Західного регіону',
    speakerImg: new URL('../assets/speakers/gladishiva.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/gladishiva_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '08:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'report',
    time: '11:00',
    desc: '300 га за 3 роки. Мотивація і лайфхаки',
    speakerName: 'Оксана Лукіянчук',
    speakerDesc: 'ФГ "КЛАПТИК СТЕПУ"',
    speakerImg: new URL('../assets/speakers/lukiyanchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/lukiyanchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '16:40',
    desc: 'Стратегічна сесія: як та навіщо планувати майбутнє агрокомпанії?',
    speakers: [
      {
        speakerName: 'Богдан Кривіцький',
        speakerDesc: 'Технічний директор ІМК',
        speakerImg: new URL('../assets/speakers/krivickiy.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/krivickiy_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Андрій Капріца',
        speakerDesc: 'ФГ «Флора А.А.»',
        speakerImg: new URL('../assets/speakers/caprica.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/caprica_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Богдан Круглик',
        speakerDesc: 'Директор «Френдт»',
        speakerImg: new URL('../assets/speakers/kruglic.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/kruglic_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Ірина Ващенко',
        speakerDesc: 'Директор «Землероб»',
        speakerImg: new URL('../assets/speakers/vaschenko_i.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
      },
    ],
  },
];
