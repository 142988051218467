export const technology2 = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.facebook.com/zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '12:40',
    desc: 'Ранні соняшники - майбутнє Українського соняшника',
    speakerName: 'Богдан Лукіянчук',
    speakerDesc: 'Agro Digital Holding',
    speakerImg: new URL('../assets/speakers/b_lukiyanchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/b_lukiyanchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:30',
    desc: 'Фосфорний пазл: Умови ефективності стартових добрив',
    speakerName: 'Ольга Капітанська',
    speakerDesc: 'Керівниця науково-дослідного відділу НВК «Квадрат», к.б.н',
    speakerImg: new URL('../assets/speakers/kapitanska.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kapitanska_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '13:00',
    desc: 'Штучний інтелект у сільському господарстві: як технології змінюють систему прийняття рішень',
    speakerName: 'Всеволод Генін',
    speakerDesc: 'Head of agricultural research, співзасновник',
    speakerImg: new URL('../assets/speakers/vsevolod.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vsevolod_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:10',
    desc: 'Інноваційні технології в протруюванні насіння',
    speakerName: 'Тарас Вакула',
    speakerDesc: 'Заступник Генерального директора з ЗЕД',
    speakerImg: new URL('../assets/speakers/vakula.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vakula_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '10:00',
    desc: 'Необхідність. Ефективність. Післядія. Резистентність. Заборона."',
    speakerName: 'Сергій Богомаз',
    speakerDesc: 'експерт та керівник "Агрокомпанія',
    speakerImg: new URL('../assets/speakers/bogomaz.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '10:30',
    desc: 'Технології вирощування солодкої кукурудзи забезпечуючи максимальну поживну цінність',
    speakerName: 'Руперт Ліндлмаєр',
    speakerDesc: 'фермер з Німеччини',
    speakerImg: new URL('../assets/speakers/rupert.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/rupert_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:30',
    desc: 'Особливості створення пешої в світі фіолетово-червоної антоціанової суперсолодкої кукурудзи із геном цукристості Sh2',
    speakerName: 'Михайло Нагорняк',
    speakerDesc: 'Керівник ПП "Мнагор"',
    speakerImg: new URL('../assets/speakers/nagornyak.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/nagornyak_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '11:50',
    desc: 'Підсумки огляду полів ПАК24. Найпоширеніші проблеми України, що лімітують результати в полях',
    speakers: [
      {
        speakerName: 'Йорк Байєр',
        speakerDesc: 'Власник компанії Bayer Handelsvertretung',
        speakerImg: new URL('../assets/speakers/YorkBayer.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/rupert_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Роман Гринишин',
        speakerDesc: 'Власник Travelite AGRO',
        speakerImg: new URL('../assets/speakers/grinshin.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/grinshin_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'break',
    time: '08:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'break',
    time: '09:40',
    title: 'ОФІЦІЙНЕ ВІДКРИТТЯ REFARM 2025',
  },
  {
    type: 'break',
    time: '13:20',
    title: 'ФУРШЕТ ТА НЕТВОРКІНГ',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
];
